import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import styled, { css } from 'styled-components';
import { ifNotProp, ifProp } from 'styled-tools';

import MonthToggle, {
  displayModes,
  ToggleContainer
} from '../../components/Widgets/SharedComponents/MonthToggle';
import IndexContext from '../../context/IndexContext';
import MarketDataContext from '../../context/MarketDataContext';
import MonthContext from '../../context/MonthContext';
import SiteSettingsContext from '../../context/SiteSettingsContext';
import {
  DATA_SOURCE_FUTURES,
  DATA_SOURCE_INDEX,
  pageModes
} from '../../utils/constants';
import { findLastTradeTime, formatTime } from '../../utils/functions';
import { FootNoteText } from '../PriceFeeds/Feeds';
import SpinnerContainer from '../SpinnerContainer';
import QuotePageFeed from './QuotePageFeed';
import SingleProductFeed from './SingleProductFeed';

const Container = styled.div`
  overflow: auto;
  max-height: 65vh;

  ${ToggleContainer} {
    button {
      height: 23px;
    }

    ${ifNotProp(
      { $pageMode: pageModes.QUOTE },
      css`
        margin-bottom: 1rem;
      `
    )}
  }

  ${ifProp(
    { $pageMode: pageModes.QUOTE },
    css`
      margin-top: 0.5rem;
    `
  )}
`;

const propTypes = {
  symbol: PropTypes.string,
  symbolObject: PropTypes.object
};

const defaultState = { symbol: '', symbolObject: {} };

function ProductPriceFeed({ symbol, symbolObject }) {
  const {
    summaryTradeData,
    isSummaryTradeLoading,
    candleData,
    isCandleDataLoading
  } = useContext(MarketDataContext);
  const pageMode = useSelector(
    (state) => state.pageModes && state.pageModes[symbol]
  );
  const indices = useContext(IndexContext);
  const { selectedMonth } = useContext(MonthContext);
  const siteSettings = useContext(SiteSettingsContext);
  const dataSource =
    (siteSettings.products[symbol] &&
      siteSettings.products[symbol].price_cards_data_source) ||
    DATA_SOURCE_INDEX;

  const activeSymbol =
    dataSource === DATA_SOURCE_FUTURES ? selectedMonth.symbol : indices[symbol];

  if (isSummaryTradeLoading || isCandleDataLoading) {
    return (
      <SpinnerContainer height='100'>
        <Spinner size='lg' />
      </SpinnerContainer>
    );
  }

  const lastTradeTime =
    findLastTradeTime(Object.values(summaryTradeData)) || null;

  return (
    <>
      {pageMode !== pageModes.QUOTE && (
        <FootNoteText>
          {lastTradeTime
            ? `as of ${formatTime(lastTradeTime)}`
            : 'No trade data available right now'}
        </FootNoteText>
      )}
      <Container $pageMode={pageMode}>
        <MonthToggle
          symbol={symbol}
          displayMode={displayModes.MONTH_NAME}
          justify={'left'}
        />
        {pageMode === pageModes.QUOTE ? (
          <QuotePageFeed data={candleData} symbol={symbol} />
        ) : (
          <>
            <SingleProductFeed
              summaryTradeData={summaryTradeData}
              symbol={symbol}
              symbolObject={symbolObject}
              activeSymbol={activeSymbol}
              dataSource={dataSource}
            />
          </>
        )}
      </Container>
    </>
  );
}

ProductPriceFeed.propTypes = propTypes;
ProductPriceFeed.defaultState = defaultState;

export default ProductPriceFeed;
